import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col } from 'react-bootstrap';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h3>Manicure:</h3>
    <p>This service includes the filing and shaping of the nails, pushing back and removal of any excess cuticle, an application of cuticle moisturizer, followed by a hand massage and polish of your choice. <br />
      <strong>$40</strong>
    </p>
    <h3>Pedicure:</h3>
    <p>This service includes the filing and shaping of the nails, pushing back and removal of excess cuticle, buffing of the nails to smooth their ridged surfaces, ingrown toe nail attention, filing of the soles in addition to other calloused areas, followed by an indulgent body butter foot massage and polish of your choice. <br />
    <strong>$60</strong></p>

    <h3>Manicure & Pedicure Combination:</h3>
    <p>
      Packaged rates are provided for manicure & pedicure combinations including all Shellac services.<br />
      <strong>$95</strong>
    </p>

    <h3>Exfoliation:</h3>
    <p>
      Our exfoliating salt scrub and extended massage may be added to any service.
      <br />
      <strong>$15</strong>
    </p>

    

    <h3>Deluxe Shellac Manicure:</h3>
    <p>
      Pamper yourself with the two week manicure! This service includes a complete manicure, two treatments and CND Shellac application. A deep conditioning cuticle eraser is applied to help minimize the calloused and rough areas of your fingers. After your nails are manicured, your hands are spoiled with an exfoliating citrus scrub and illuminating hand masque topped off by an extended hand and arm massage. An excellent choice for weddings, vacations, special occasions or in alteration with the Express Shellac manicure. Goes on like nail polish. Wears like gel. No dry time and lasts for weeks! This service includes shellac removal and reapplication.<br />
      <strong>$75</strong>
    </p>

    <h3>Express Shellac Manicure:</h3>
    <p>
      Want your polish to last for weeks, but don’t have time for all the fluffs? Try the Express! CND Shellac is applied to your beautiful, newly shaped nails and you get to enjoy the benefits of being dry when you leave. A great alternative for a “woman on the go”. Best results are seen in alteration with the Deluxe Shellac Manicure. This service includes shellac removal and reapplication. <br />
      <strong>$55</strong>
    </p>

    

    <h3>Shellac Removal A La Carte:</h3>
    <p>
    Your fingers and toes are wrapped carefully for the removal process. Your nails are re shaped, cuticles are tidied, then conditioned with Solar Oil and a brief rub down.<br /><strong>$25</strong>
<br />
    {/* <strong>$90</strong> */}
    </p>

    <Row>
      <Col lg={10} md={8} sm={6} xs={6}>
        <h3>Polish Change:</h3>
        <p>Color of your choice <br /><strong>$15+</strong></p>

        <h3>Repairs:</h3>
        <p>Silk Wrap <br /><strong>$5+</strong></p>
      </Col>
      <Col lg={2} md={4} sm={6} xs={6}>
        <StaticImage src="../images/maya-diaz-portrait.jpg" alt="Maya Diaz" />
        {/* <Image src={MayaDiazPortrait} alt="Maya Diaz" fluid /> */}
      </Col>
    </Row>
    
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Our Menu" />

export default IndexPage
